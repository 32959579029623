.App{
  font-family: 'B612 Mono', monospace;

}

.nav{
  overflow: hidden;
  background-color: #fff;
  /* border: 1px solid black; */
  height: 9vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;

}

.nav ul{
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  font-weight: 100;
  
}

.nav a{
  text-decoration: none;
  color: #0da5da;
  top: 20px;
}

.nav .about-me .projects a{
  top: 15px;
}


.header{
  background: url("./images/Portfolio_hero.png") repeat 0 0;
  background-size: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  font-size: 20px;
  color: #fff;
  box-shadow: 0px 10px 45px black;
   animation: gradientBG 40s ease infinite; 

}
@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.header h1{
  font-family: 'B612 Mono', monospace;
  font-weight: 100;
  
    -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
 
.header img{
 margin: auto;
 position: relative;
 bottom: 3em;
}

.header p{
  text-align: center;
  
}

.about{
  font-family: 'B612 Mono', monospace;
  margin-bottom: 5%;
  
}
.about h4{
  text-align: center;
  margin: 20px 80px ;
}

.about h2{
  margin-top: 50px;
}


.about .icons{
  /* border: 2px solid blueviolet; */
  margin-top: 3%;
  font-family: 'B612 Mono', monospace;
  font-weight: 100;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  -webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

.about h2{
  text-align: center;
}

.about .icons img{
  margin-top: 15px;
  width: 85px;
  
}

.projects-container{
  font-family: 'B612 Mono', monospace;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.872);
  color: #fff;
  /* border: 2px solid red; */
  top: 0;
}

.projects-container .sunglass img{
  width: 30%;
  height: 80%;
  
}
.projects-container .move img{
  width: 30%;
  height: 45%;
  margin-top: 5%;
}
.projects-container .move{
  margin: auto;
}
.projects-container .eyesahyt{
  margin-bottom: 60px;
}

.projects-container .eyesahyt img{
  width: 30%;
  height: 75%;
  margin-bottom: 0px;
  margin-top: 40px;
}

.projects-container button{
  border-radius: 4px;
  height: 30px;
  width: 80px;
  background-color: #da1b0d;
  border-color:#da1b0d;
  
}

.projects-container button:hover{
  background-color:black ;
  border: black;
}

.projects-container a{
  color: white;
  font-weight: 800;
  text-decoration: none;

}


.footer {
  color: white;
  background-color: #0da5da;
  height: 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: none;
  font-family: 'B612 Mono', monospace;
  font-weight: 700;
}

.footer img{
  width: 40px;
  height: 40px;
  padding: 20px;
  justify-content: space-evenly;
  
}

/* .footer:hover img{
  background-image: white;
} */



@media(max-width:740px) {
.projects-container{
  display: flex;
  flex-direction: column;
}
}
